export type Language = 'en' | 'de' | 'es' | 'ja' | 'ko' | 'ru' | 'zh';

export interface Translations {
  [id: string]: string;
}

export interface I18nMessages {
  en: Translations;
  de: Translations;
  es: Translations;
  ja: Translations;
  ko: Translations;
  ru: Translations;
  zh: Translations;
}

const i18nTranslations: I18nMessages = {
  "en": {
    "footer.linkTerms.text": "Terms of Use",
    "footer.linkPrivacyPolicy.text": "Privacy Policy",
    "footer.linkCookiePolicy.text": "Cookie Policy",
    "footer.linkSecurity.text": "Security",
    "footer.copyright": "© {currentYear} Circle Internet Services, Inc.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "signup.header.linkText1": "Already have an account?",
    "signup.header.linkText2": " Log in",
    "signup.hero.header": "build now",
    "signup.hero.subheader": "You’re minutes away from your first green build.",
    "signup.hero.paragraph": "No credit card required.",
    "signup.hero.titleBtns": "Start building for free",
    "signup.hero.primaryBtn": "Sign up with GitHub",
    "signup.hero.primaryBtnDropdown1": "All repos",
    "signup.hero.primaryBtnDropdown2": "Public repos only",
    "signup.hero.secondaryBtn": "Sign up with Bitbucket Cloud",
    "signup.hero.emailBtn": "Sign up with email",
    "signup.hero.gitlabBtn": "Sign up with GitLab",
    "signup.hero.signupBtn": "Sign up",
    "signup.hero.emailLabel": "Email",
    "signup.hero.emailInstructions": "Enter your email. Example: name@domain.com",
    "signup.hero.passwordLabel": "Password",
    "signup.hero.passwordInstructions": "Use at least 10 characters, with 3 of the following: number, special character (!@#$%^&*), upper or lower case letter",
    "signup.hero.passwordErrorMsg": "Enter 10 or more characters. Include at least 3 of the following: number (0-9), special character (!@#$%^&*), upper case letter (A-Z), or lower case letter (a-z).",
    "signup.hero.dividerText": "or",
    "signup.hero.ff_vcsSubheading": "Works with GitHub, GitLab, and Bitbucket",
    "vcsAuthorize.header.linkText1": "Don’t have an account?",
    "vcsAuthorize.header.linkText2": " Sign up",
    "error.title": "We’re sorry, something went wrong.",
    "error.content": "Please refresh and try again.",
    "signup-stackoverflow.hero.header": "Sign up now",
    "signup-stackoverflow.hero.subheader": "Welcome, Stack Overflow Listeners!",
    "signup-stackoverflow.hero.paragraph": "Start building on our free plan and get access to unlimited projects on our fully-hosted cloud platform.",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "Start building",
    "signup-stackoverflow.hero.primaryBtn": "Sign up with GitHub",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "All Repos",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "Public Repos Only",
    "signup-stackoverflow.hero.secondaryBtn": "Sign up with Bitbucket Cloud",
    "signup-stackoverflow.hero.emailBtn": "Sign up with Email",
    "signup-stackoverflow.hero.gitlabBtn": "Sign up with GitLab",
    "vcsAuthorized.hero.alert.sessionExpired.title": "Your session has expired.",
    "vcsAuthorized.hero.alert.sessionExpired.content": "Please log back in.",
    "vcsAuthorized.hero.alert.emailVerified.title": "Email verified.",
    "vcsAuthorized.hero.alert.emailVerified.content": "Your email is verified. You can continue using the application.",
    "vcsAuthorized.hero.header": "build something great.",
    "vcsAuthorized.hero.paragraph": "You’ll be taken to GitHub or Bitbucket to authenticate.",
    "vcsAuthorized.hero.signUpTitle": "Don’t have an account?",
    "vcsAuthorized.hero.signUpBtn": "Sign up",
    "vcsAuthorized.hero.logInBtn": "Log in",
    "vcsAuthorized.hero.titleBtns": "Log in to CircleCI",
    "vcsAuthorized.hero.welcomeTitleBtn": "Welcome back to CircleCI!",
    "vcsAuthorized.hero.primaryBtn": "Log in with GitHub",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "Signed up for CircleCI with GitHub or ",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "Bitbucket before September 2023?",
    "vcsAuthorized.hero.primaryBtnDropdown1": "All repos",
    "vcsAuthorized.hero.primaryBtnDropdown2": "Public repos only",
    "vcsAuthorized.hero.secondaryBtn": "Log in with Bitbucket Cloud",
    "vcsAuthorized.hero.emailBtn": "Log in with email",
    "vcsAuthorized-enterprise.hero.header": "Log in to CircleCI server",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCI’s continuous integration and deployment platform helps software teams rapidly ship code they trust by automating the build, test, and deploy process.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Log In With GitHub",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "By logging in or signing up, you are agreeing to our",
    "disclaimer.part1.vcsAuthorized": "By logging in or signing up, you are agreeing to our",
    "disclaimer.link1Text": "SaaS Agreement",
    "disclaimer.part2": "and",
    "disclaimer.link2Text": "Privacy Policy",
    "recaptcha.part1": " Protected by reCAPTCHA, Google ",
    "recaptcha.privacyLinkText": "Privacy Policy",
    "recaptcha.part2": " and ",
    "recaptcha.termsLinkText": "Terms of Service",
    "recaptcha.part3": " apply.",
    "siteMap.column1.heading": "Product",
    "siteMap.column2.heading": "Support",
    "siteMap.column3.heading": "Resources",
    "siteMap.column4.heading": "Company",
    "siteMap.column5.heading": "Getting Started",
    "siteMap.linkOverview.text": "Overview",
    "siteMap.linkPricing.text": "Pricing",
    "siteMap.linkIntegrations.text": "Integrations",
    "siteMap.linkOpenSource.text": "Open Source",
    "siteMap.linkEnterprise.text": "Enterprise",
    "siteMap.linkChangeLog.text": "Changelog",
    "siteMap.linkDocumentation.text": "Documentation",
    "siteMap.linkSupport.text": "Get Support",
    "siteMap.linkForum.text": "Community Forum",
    "siteMap.linkStatus.text": "System Status",
    "siteMap.linkRequests.text": "Feature Requests",
    "siteMap.linkPremium.text": "Premium Support",
    "siteMap.linkBlog.text": "Blog",
    "siteMap.linkCaseStudies.text": "Case Studies",
    "siteMap.linkEbooks.text": "Ebooks",
    "siteMap.linkReports.text": "Reports",
    "siteMap.linkWebinars.text": "Webinars",
    "siteMap.linkVideos.text": "Videos",
    "siteMap.linkAbout.text": "About Us",
    "siteMap.linkCareers.text": "Careers ",
    "siteMap.linkCareers.subText": "(We're hiring!)",
    "siteMap.linkTeam.text": "Team",
    "siteMap.linkContact.text": "Contact Us",
    "siteMap.linkPartner.text": "Partner With Us",
    "siteMap.linkPress.text": "Newsroom",
    "siteMap.linkCI.text": "What is CI?",
    "siteMap.linkGetStarted.text": "How to get started on CircleCI",
    "trustedBy": "Trusted by more than 30,000 organizations",
    "links.internal.signup": "/signup/",
    "links.internal.vcsAuthorize": "/vcs-authorize/",
    "links.external.root": "https://circleci.com/",
    "links.external.product": "https://circleci.com/product/",
    "links.external.pricing": "https://circleci.com/pricing/",
    "links.external.integrations": "https://circleci.com/integrations/",
    "links.external.openSource": "https://circleci.com/open-source/",
    "links.external.enterprise": "https://circleci.com/enterprise/",
    "links.external.changeLog": "https://circleci.com/changelog/",
    "links.external.docs": "https://circleci.com/docs/",
    "links.external.support": "https://support.circleci.com/hc/en-us/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/customers/",
    "links.external.ebooks": "https://circleci.com/resources/#ebooks",
    "links.external.reports": "https://circleci.com/resources/#reports",
    "links.external.webinars": "https://circleci.com/resources/webinars/",
    "links.external.videos": "https://circleci.com/resources/#videos",
    "links.external.about": "https://circleci.com/about/",
    "links.external.careers": "https://circleci.com/careers/",
    "links.external.team": "https://circleci.com/about/team/",
    "links.external.contact": "https://circleci.com/contact/",
    "links.external.partners": "https://circleci.com/partners/",
    "links.external.newsroom": "https://circleci.com/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/circleci",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/security/",
    "links.external.login": "https://circleci.com/authentication/login?f=gho",
    "links.external.loginBitBucket": "https://circleci.com/authentication/login?f=bbo",
    "links.external.authSignUp": "https://circleci.com/authentication/signup/",
    "links.external.authLogin": "https://circleci.com/authentication/login/",
    "links.external.loginPublic": "https://circleci.com/authentication/login?f=gho&s=public",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=en",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=en",
    "vcsAuthorizePage.title": "Log in - CircleCI",
    "vcsAuthorizePage.metaTitle": "Log in to access CircleCI",
    "vcsAuthorizePage.metaDescription": "Log into your CircleCI account to view the status of your builds. If you don’t have an account, sign up at https://circleci.com/signup/",
    "vcsAuthorizePage.metaDescriptionEnterprise": "Log into your CircleCI account",
    "signupPage.title": "Signup - CircleCI",
    "signupPage.metaTitle": "Start building CI/CD pipelines for free today",
    "signupPage.metaDescription": "Sign up for CircleCI and get access to 6,000 free build minutes per month, forever. Connect CircleCI to your VCS on GitHub, GitLab, or Bitbucket, and build on Linux, macOS, Windows, Android, GPU, and Docker.",
    "signupPage.canonicalLink": "https://circleci.com/signup/"
  },
  "de": {
    "footer.linkTerms.text": "Nutzungsbedingungen",
    "footer.linkPrivacyPolicy.text": "Datenschutzerklärung",
    "footer.linkCookiePolicy.text": "Cookie-Richtlinie",
    "footer.linkSecurity.text": "Sicherheit",
    "footer.copyright": "© {currentYear} Circle Internet Services, Inc.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., Alle Rechte vorbehalten.",
    "signup.header.linkText1": "Bereits ein Konto vorhanden?",
    "signup.header.linkText2": " Anmelden",
    "signup.hero.header": "Jetzt entwickeln",
    "signup.hero.subheader": "Nur noch wenige Minuten bis zu Ihrem ersten erfolgreichen Build.",
    "signup.hero.paragraph": "Keine Kreditkarte erforderlich.",
    "signup.hero.titleBtns": "Kostenlos starten",
    "signup.hero.primaryBtn": "Mit GitHub registrieren",
    "signup.hero.primaryBtnDropdown1": "Alle Repositories",
    "signup.hero.primaryBtnDropdown2": "Nur öffentliche Repositories",
    "signup.hero.secondaryBtn": "Mit Bitbucket Cloud registrieren",
    "signup.hero.emailBtn": "Mit E-Mail registrieren",
    "signup.hero.gitlabBtn": "Mit GitLab registrieren",
    "signup.hero.signupBtn": "Registrieren",
    "signup.hero.emailLabel": "E-Mail",
    "signup.hero.emailInstructions": "Geben Sie Ihre E-Mail-Adresse ein. Beispiel: name@domain.com",
    "signup.hero.passwordLabel": "Passwort",
    "signup.hero.passwordInstructions": "Verwenden Sie mindestens 10 Zeichen, mit 3 der folgenden: Zahl, Sonderzeichen (!@#$%^&*), Groß- oder Kleinbuchstaben",
    "signup.hero.passwordErrorMsg": "Geben Sie 10 oder mehr Zeichen ein. Verwenden Sie mindestens 3 der folgenden: Zahlen (0-9), Sonderzeichen (!@#$%^&*), Großbuchstaben (A-Z) oder Kleinbuchstaben (a-z).",
    "signup.hero.dividerText": "oder",
    "signup.hero.ff_vcsSubheading": "Funktioniert mit GitHub, GitLab und Bitbucket",
    "vcsAuthorize.header.linkText1": "Noch kein Konto?",
    "vcsAuthorize.header.linkText2": " Registrieren",
    "error.title": "Es tut uns leid, etwas ist schiefgelaufen.",
    "error.content": "Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
    "signup-stackoverflow.hero.header": "Jetzt registrieren",
    "signup-stackoverflow.hero.subheader": "Willkommen, Stack Overflow Zuhörer!",
    "signup-stackoverflow.hero.paragraph": "Starten Sie mit unserem kostenlosen Plan und erhalten Sie Zugriff auf unbegrenzte Projekte auf unserer vollständig gehosteten Cloud-Plattform.",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "Jetzt loslegen",
    "signup-stackoverflow.hero.primaryBtn": "Mit GitHub registrieren",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "Alle Repositories",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "Nur öffentliche Repositories",
    "signup-stackoverflow.hero.secondaryBtn": "Mit Bitbucket Cloud registrieren",
    "signup-stackoverflow.hero.emailBtn": "Mit E-Mail registrieren",
    "signup-stackoverflow.hero.gitlabBtn": "Mit GitLab registrieren",
    "vcsAuthorized.hero.alert.sessionExpired.title": "Ihre Sitzung ist abgelaufen.",
    "vcsAuthorized.hero.alert.sessionExpired.content": "Bitte melden Sie sich erneut an.",
    "vcsAuthorized.hero.alert.emailVerified.title": "E-Mail bestätigt.",
    "vcsAuthorized.hero.alert.emailVerified.content": "Ihre E-Mail-Adresse wurde bestätigt. Sie können die Anwendung nun weiter nutzen.",
    "vcsAuthorized.hero.header": "Entwickeln Sie etwas Großartiges.",
    "vcsAuthorized.hero.paragraph": "Sie werden zu GitHub oder Bitbucket weitergeleitet, um sich zu authentifizieren.",
    "vcsAuthorized.hero.signUpTitle": "Noch kein Konto?",
    "vcsAuthorized.hero.signUpBtn": "Registrieren",
    "vcsAuthorized.hero.logInBtn": "Anmelden",
    "vcsAuthorized.hero.titleBtns": "Bei CircleCI anmelden",
    "vcsAuthorized.hero.welcomeTitleBtn": "Willkommen zurück bei CircleCI!",
    "vcsAuthorized.hero.primaryBtn": "Mit GitHub anmelden",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "Haben Sie sich vor September 2023 bei CircleCI mit GitHub oder ",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "Bitbucket registriert?",
    "vcsAuthorized.hero.primaryBtnDropdown1": "Alle Repositories",
    "vcsAuthorized.hero.primaryBtnDropdown2": "Nur öffentliche Repositories",
    "vcsAuthorized.hero.secondaryBtn": "Mit Bitbucket Cloud anmelden",
    "vcsAuthorized.hero.emailBtn": "Mit E-Mail anmelden",
    "vcsAuthorized-enterprise.hero.header": "Beim CircleCI Server anmelden",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCIs Plattform für kontinuierliche Integration und Bereitstellung hilft Softwareteams, vertrauenswürdigen Code schnell auszuliefern, indem der Build-, Test- und Bereitstellungsprozess automatisiert wird.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Mit GitHub anmelden",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "Mit der Anmeldung oder Registrierung stimmen Sie unserer",
    "disclaimer.part1.vcsAuthorized": "Mit der Anmeldung oder Registrierung stimmen Sie unserer",
    "disclaimer.link1Text": "SaaS-Vereinbarung",
    "disclaimer.part2": "und",
    "disclaimer.link2Text": "Datenschutzerklärung",
    "recaptcha.part1": " Geschützt durch reCAPTCHA, es gelten die Google ",
    "recaptcha.privacyLinkText": "Datenschutzerklärung",
    "recaptcha.part2": " und ",
    "recaptcha.termsLinkText": "Nutzungsbedingungen",
    "recaptcha.part3": ".",
    "siteMap.column1.heading": "Produkt",
    "siteMap.column2.heading": "Support",
    "siteMap.column3.heading": "Ressourcen",
    "siteMap.column4.heading": "Unternehmen",
    "siteMap.column5.heading": "Erste Schritte",
    "siteMap.linkOverview.text": "Übersicht",
    "siteMap.linkPricing.text": "Preise",
    "siteMap.linkIntegrations.text": "Integrationen",
    "siteMap.linkOpenSource.text": "Open Source",
    "siteMap.linkEnterprise.text": "Enterprise",
    "siteMap.linkChangeLog.text": "Changelog",
    "siteMap.linkDocumentation.text": "Dokumentation",
    "siteMap.linkSupport.text": "Support erhalten",
    "siteMap.linkForum.text": "Community-Forum",
    "siteMap.linkStatus.text": "Systemstatus",
    "siteMap.linkRequests.text": "Feature-Wünsche",
    "siteMap.linkPremium.text": "Premium-Support",
    "siteMap.linkBlog.text": "Blog",
    "siteMap.linkCaseStudies.text": "Fallstudien",
    "siteMap.linkEbooks.text": "E-Books",
    "siteMap.linkReports.text": "Berichte",
    "siteMap.linkWebinars.text": "Webinare",
    "siteMap.linkVideos.text": "Videos",
    "siteMap.linkAbout.text": "Über uns",
    "siteMap.linkCareers.text": "Karriere ",
    "siteMap.linkCareers.subText": "(Wir stellen ein!)",
    "siteMap.linkTeam.text": "Team",
    "siteMap.linkContact.text": "Kontakt",
    "siteMap.linkPartner.text": "Partner werden",
    "siteMap.linkPress.text": "Presse",
    "siteMap.linkCI.text": "Was ist CI?",
    "siteMap.linkGetStarted.text": "Erste Schritte mit CircleCI",
    "trustedBy": "Wird von mehr als 30.000 Organisationen vertraut",
    "links.internal.signup": "/de/signup/",
    "links.internal.vcsAuthorize": "/de/vcs-authorize/",
    "links.external.root": "https://circleci.com/de/",
    "links.external.product": "https://circleci.com/product/",
    "links.external.pricing": "https://circleci.com/pricing/",
    "links.external.integrations": "https://circleci.com/integrations/",
    "links.external.openSource": "https://circleci.com/open-source/",
    "links.external.enterprise": "https://circleci.com/enterprise/",
    "links.external.changeLog": "https://circleci.com/changelog/",
    "links.external.docs": "https://circleci.com/docs/",
    "links.external.support": "https://support.circleci.com/hc/en-us/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/customers/",
    "links.external.ebooks": "https://circleci.com/resources/#ebooks",
    "links.external.reports": "https://circleci.com/resources/#reports",
    "links.external.webinars": "https://circleci.com/resources/webinars/",
    "links.external.videos": "https://circleci.com/resources/#videos",
    "links.external.about": "https://circleci.com/about/",
    "links.external.careers": "https://circleci.com/careers/",
    "links.external.team": "https://circleci.com/about/team/",
    "links.external.contact": "https://circleci.com/de/contact/",
    "links.external.partners": "https://circleci.com/partners/",
    "links.external.newsroom": "https://circleci.com/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/de/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/circleci",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/security/",
    "links.external.login": "https://circleci.com/authentication/login?f=gho",
    "links.external.loginBitBucket": "https://circleci.com/authentication/login?f=bbo",
    "links.external.authSignUp": "https://circleci.com/authentication/signup/",
    "links.external.authLogin": "https://circleci.com/authentication/login/",
    "links.external.loginPublic": "https://circleci.com/authentication/login?f=gho&s=public",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=de-DE",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=de-DE",
    "vcsAuthorizePage.title": "Anmelden - CircleCI",
    "vcsAuthorizePage.metaTitle": "Bei CircleCI anmelden",
    "vcsAuthorizePage.metaDescription": "Melden Sie sich bei Ihrem CircleCI-Konto an, um den Status Ihrer Builds einzusehen. Falls Sie noch kein Konto haben, registrieren Sie sich unter https://circleci.com/de/signup/",
    "vcsAuthorizePage.metaDescriptionEnterprise": "Melden Sie sich bei Ihrem CircleCI-Konto an",
    "signupPage.title": "Registrierung - CircleCI",
    "signupPage.metaTitle": "Starten Sie noch heute kostenlos mit CI/CD-Pipelines",
    "signupPage.metaDescription": "Registrieren Sie sich bei CircleCI und erhalten Sie dauerhaft 6.000 kostenlose Build-Minuten pro Monat. Verbinden Sie CircleCI mit Ihrer VCS auf GitHub, GitLab oder Bitbucket und entwickeln Sie auf Linux, macOS, Windows, Android, GPU und Docker.",
    "signupPage.canonicalLink": "https://circleci.com/de/signup/"
  },
  "es": {
    "footer.linkTerms.text": "Términos de uso",
    "footer.linkPrivacyPolicy.text": "Política de privacidad",
    "footer.linkCookiePolicy.text": "Política de cookies",
    "footer.linkSecurity.text": "Seguridad",
    "footer.copyright": "© {currentYear} Circle Internet Services, Inc.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., Todos los derechos reservados.",
    "signup.header.linkText1": "¿Ya tienes una cuenta?",
    "signup.header.linkText2": " Iniciar sesión",
    "signup.hero.header": "construye ahora",
    "signup.hero.subheader": "Estás a minutos de tu primera compilación exitosa.",
    "signup.hero.paragraph": "No se requiere tarjeta de crédito.",
    "signup.hero.titleBtns": "Empieza a construir gratis",
    "signup.hero.primaryBtn": "Registrarse con GitHub",
    "signup.hero.primaryBtnDropdown1": "Todos los repositorios",
    "signup.hero.primaryBtnDropdown2": "Solo repositorios públicos",
    "signup.hero.secondaryBtn": "Registrarse con Bitbucket Cloud",
    "signup.hero.emailBtn": "Registrarse con correo electrónico",
    "signup.hero.gitlabBtn": "Registrarse con GitLab",
    "signup.hero.signupBtn": "Registrarse",
    "signup.hero.emailLabel": "Correo electrónico",
    "signup.hero.emailInstructions": "Ingresa tu correo electrónico. Ejemplo: nombre@dominio.com",
    "signup.hero.passwordLabel": "Contraseña",
    "signup.hero.passwordInstructions": "Usa al menos 10 caracteres, con 3 de los siguientes: número, carácter especial (!@#$%^&*), letra mayúscula o minúscula",
    "signup.hero.passwordErrorMsg": "Ingresa 10 o más caracteres. Incluye al menos 3 de los siguientes: número (0-9), carácter especial (!@#$%^&*), letra mayúscula (A-Z) o letra minúscula (a-z).",
    "signup.hero.dividerText": "o",
    "signup.hero.ff_vcsSubheading": "Compatible con GitHub, GitLab y Bitbucket",
    "vcsAuthorize.header.linkText1": "¿No tienes una cuenta?",
    "vcsAuthorize.header.linkText2": " Registrarse",
    "error.title": "Lo sentimos, algo salió mal.",
    "error.content": "Por favor, actualiza la página e inténtalo de nuevo.",
    "signup-stackoverflow.hero.header": "Regístrate ahora",
    "signup-stackoverflow.hero.subheader": "¡Bienvenidos, oyentes de Stack Overflow!",
    "signup-stackoverflow.hero.paragraph": "Comienza con nuestro plan gratuito y obtén acceso a proyectos ilimitados en nuestra plataforma cloud totalmente gestionada.",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "Empieza a construir",
    "signup-stackoverflow.hero.primaryBtn": "Registrarse con GitHub",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "Todos los repositorios",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "Solo repositorios públicos",
    "signup-stackoverflow.hero.secondaryBtn": "Registrarse con Bitbucket Cloud",
    "signup-stackoverflow.hero.emailBtn": "Registrarse con correo electrónico",
    "signup-stackoverflow.hero.gitlabBtn": "Registrarse con GitLab",
    "vcsAuthorized.hero.alert.sessionExpired.title": "Tu sesión ha expirado.",
    "vcsAuthorized.hero.alert.sessionExpired.content": "Por favor, vuelve a iniciar sesión.",
    "vcsAuthorized.hero.alert.emailVerified.title": "Correo electrónico verificado.",
    "vcsAuthorized.hero.alert.emailVerified.content": "Tu correo electrónico está verificado. Puedes continuar usando la aplicación.",
    "vcsAuthorized.hero.header": "construye algo grandioso.",
    "vcsAuthorized.hero.paragraph": "Serás dirigido a GitHub o Bitbucket para autenticarte.",
    "vcsAuthorized.hero.signUpTitle": "¿No tienes una cuenta?",
    "vcsAuthorized.hero.signUpBtn": "Registrarse",
    "vcsAuthorized.hero.logInBtn": "Iniciar sesión",
    "vcsAuthorized.hero.titleBtns": "Iniciar sesión en CircleCI",
    "vcsAuthorized.hero.welcomeTitleBtn": "¡Bienvenido de nuevo a CircleCI!",
    "vcsAuthorized.hero.primaryBtn": "Iniciar sesión con GitHub",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "¿Te registraste en CircleCI con GitHub o ",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "Bitbucket antes de septiembre de 2023?",
    "vcsAuthorized.hero.primaryBtnDropdown1": "Todos los repositorios",
    "vcsAuthorized.hero.primaryBtnDropdown2": "Solo repositorios públicos",
    "vcsAuthorized.hero.secondaryBtn": "Iniciar sesión con Bitbucket Cloud",
    "vcsAuthorized.hero.emailBtn": "Iniciar sesión con correo electrónico",
    "vcsAuthorized-enterprise.hero.header": "Iniciar sesión en el servidor CircleCI",
    "vcsAuthorized-enterprise.hero.paragraph": "La plataforma de integración y despliegue continuo de CircleCI ayuda a los equipos de desarrollo a distribuir código confiable rápidamente mediante la automatización del proceso de compilación, prueba y despliegue.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Iniciar sesión con GitHub",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "Al iniciar sesión o registrarte, aceptas nuestro",
    "disclaimer.part1.vcsAuthorized": "Al iniciar sesión o registrarte, aceptas nuestro",
    "disclaimer.link1Text": "Acuerdo SaaS",
    "disclaimer.part2": "y",
    "disclaimer.link2Text": "Política de privacidad",
    "recaptcha.part1": " Protegido por reCAPTCHA, se aplican la ",
    "recaptcha.privacyLinkText": "Política de privacidad",
    "recaptcha.part2": " y los ",
    "recaptcha.termsLinkText": "Términos de servicio",
    "recaptcha.part3": " de Google.",
    "siteMap.column1.heading": "Producto",
    "siteMap.column2.heading": "Soporte",
    "siteMap.column3.heading": "Recursos",
    "siteMap.column4.heading": "Empresa",
    "siteMap.column5.heading": "Primeros pasos",
    "siteMap.linkOverview.text": "Descripción general",
    "siteMap.linkPricing.text": "Precios",
    "siteMap.linkIntegrations.text": "Integraciones",
    "siteMap.linkOpenSource.text": "Código abierto",
    "siteMap.linkEnterprise.text": "Empresa",
    "siteMap.linkChangeLog.text": "Registro de cambios",
    "siteMap.linkDocumentation.text": "Documentación",
    "siteMap.linkSupport.text": "Obtener soporte",
    "siteMap.linkForum.text": "Foro de la comunidad",
    "siteMap.linkStatus.text": "Estado del sistema",
    "siteMap.linkRequests.text": "Solicitud de funciones",
    "siteMap.linkPremium.text": "Soporte Premium",
    "siteMap.linkBlog.text": "Blog",
    "siteMap.linkCaseStudies.text": "Casos de estudio",
    "siteMap.linkEbooks.text": "Libros electrónicos",
    "siteMap.linkReports.text": "Informes",
    "siteMap.linkWebinars.text": "Seminarios web",
    "siteMap.linkVideos.text": "Vídeos",
    "siteMap.linkAbout.text": "Sobre nosotros",
    "siteMap.linkCareers.text": "Empleo ",
    "siteMap.linkCareers.subText": "(¡Estamos contratando!)",
    "siteMap.linkTeam.text": "Equipo",
    "siteMap.linkContact.text": "Contáctanos",
    "siteMap.linkPartner.text": "Asóciate con nosotros",
    "siteMap.linkPress.text": "Sala de prensa",
    "siteMap.linkCI.text": "¿Qué es CI?",
    "siteMap.linkGetStarted.text": "Cómo empezar con CircleCI",
    "trustedBy": "De confianza para más de 30.000 organizaciones",
    "links.internal.signup": "/es/signup/",
    "links.internal.vcsAuthorize": "/es/vcs-authorize/",
    "links.external.root": "https://circleci.com/es/",
    "links.external.product": "https://circleci.com/product/",
    "links.external.pricing": "https://circleci.com/pricing/",
    "links.external.integrations": "https://circleci.com/integrations/",
    "links.external.openSource": "https://circleci.com/open-source/",
    "links.external.enterprise": "https://circleci.com/enterprise/",
    "links.external.changeLog": "https://circleci.com/changelog/",
    "links.external.docs": "https://circleci.com/docs/",
    "links.external.support": "https://support.circleci.com/hc/en-us/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/customers/",
    "links.external.ebooks": "https://circleci.com/resources/#ebooks",
    "links.external.reports": "https://circleci.com/resources/#reports",
    "links.external.webinars": "https://circleci.com/resources/webinars/",
    "links.external.videos": "https://circleci.com/resources/#videos",
    "links.external.about": "https://circleci.com/about/",
    "links.external.careers": "https://circleci.com/careers/",
    "links.external.team": "https://circleci.com/about/team/",
    "links.external.contact": "https://circleci.com/es/contact/",
    "links.external.partners": "https://circleci.com/partners/",
    "links.external.newsroom": "https://circleci.com/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/es/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/circleci",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/security/",
    "links.external.login": "https://circleci.com/authentication/login?f=gho",
    "links.external.loginBitBucket": "https://circleci.com/authentication/login?f=bbo",
    "links.external.authSignUp": "https://circleci.com/authentication/signup/",
    "links.external.authLogin": "https://circleci.com/authentication/login/",
    "links.external.loginPublic": "https://circleci.com/authentication/login?f=gho&s=public",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=es-ES",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=es-ES",
    "vcsAuthorizePage.title": "Iniciar sesión - CircleCI",
    "vcsAuthorizePage.metaTitle": "Iniciar sesión para acceder a CircleCI",
    "vcsAuthorizePage.metaDescription": "Inicia sesión en tu cuenta de CircleCI para ver el estado de tus compilaciones. Si no tienes una cuenta, regístrate en https://circleci.com/es/signup/",
    "vcsAuthorizePage.metaDescriptionEnterprise": "Inicia sesión en tu cuenta de CircleCI",
    "signupPage.title": "Registro - CircleCI",
    "signupPage.metaTitle": "Empieza a construir pipelines de CI/CD gratis hoy",
    "signupPage.metaDescription": "Regístrate en CircleCI y obtén acceso a 6.000 minutos de compilación gratuitos por mes, para siempre. Conecta CircleCI a tu VCS en GitHub, GitLab o Bitbucket, y compila en Linux, macOS, Windows, Android, GPU y Docker.",
    "signupPage.canonicalLink": "https://circleci.com/es/signup/"
  },
  "ja": {
    "footer.linkTerms.text": "利用規約",
    "footer.linkPrivacyPolicy.text": "プライバシーポリシー",
    "footer.linkCookiePolicy.text": "クッキーポリシー",
    "footer.linkSecurity.text": "セキュリティポリシー",
    "footer.copyright": "© {currentYear} Circle Internet Services, Inc.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "vcsAuthorize.header.linkText1": "アカウントをお持ちでないですか？",
    "vcsAuthorize.header.linkText2": "サインアップ",
    "signup.header.linkText1": "すでにアカウントをお持ちですか？",
    "signup.header.linkText2": "ログイン",
    "signup.hero.header": "ビルドを開始",
    "signup.hero.subheader": "初めてのビルド成功まであと少しです。",
    "signup.hero.paragraph": "クレジットカード不要",
    "signup.hero.titleBtns": "無料でビルドを始めましょう",
    "signup.hero.primaryBtn": "GitHub で登録",
    "signup.hero.primaryBtnDropdown1": "すべてのリポジトリ",
    "signup.hero.primaryBtnDropdown2": "公開リポジトリと連携",
    "signup.hero.secondaryBtn": "Bitbucket Cloud で登録",
    "signup.hero.emailBtn": "メールで登録",
    "signup.hero.signupBtn": "サインアップ",
    "signup.hero.gitlabBtn": "GitLab で登録",
    "signup.hero.emailLabel": "メールアドレス",
    "signup.hero.emailInstructions": "メールアドレスを記入して下さい。例: name@domain",
    "signup.hero.passwordLabel": "パスワード",
    "signup.hero.passwordInstructions": "10文字以上で入力して下さい。半角の数字、記号 (!@#$%^&*)、英大文字および小文字の中から3種類以上を組み合わせて下さい。",
    "signup.hero.passwordErrorMsg": "10文字以上で入力して下さい。半角の英小文字(a-z)と大文字(A-Z)、数字(0-9)、記号(!@#$%^&*)の中から3種類以上を組み合わせて下さい。",
    "signup.hero.dividerText": "または",
    "signup.hero.ff_vcsSubheading": "GitHub、GitLab、Bitbucketと連携可能",
    "error.title": "申し訳ありません。問題が発生しました。",
    "error.content": "ページを更新して、やり直してください。",
    "signup-stackoverflow.hero.header": "ユーザー登録",
    "signup-stackoverflow.hero.subheader": "Welcome, Stack Overflow Listeners!",
    "signup-stackoverflow.hero.paragraph": "大規模なプロジェクトも扱える無料のプランから始めることができます。GitHub で個人リポジトリをお使いでない方は、プルダウンから「公開リポジトリと連携」を選んでユーザー登録に進んでください。",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "新規アカウントを作成する",
    "signup-stackoverflow.hero.primaryBtn": "GitHub でログイン",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "全てのリポジトリと連携",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "公開リポジトリと連携",
    "signup-stackoverflow.hero.secondaryBtn": "Bitbucket Cloud でログイン",
    "signup-stackoverflow.hero.emailBtn": "メールでログイン",
    "signup-stackoverflow.hero.gitlabBtn": "GitLab で登録",
    "vcsAuthorized.hero.alert.sessionExpired.title": "セッションの有効期限が切れています。",
    "vcsAuthorized.hero.alert.sessionExpired.content": "ログインし直してください。",
    "vcsAuthorized.hero.alert.emailVerified.title": "メールアドレス確認完了。",
    "vcsAuthorized.hero.alert.emailVerified.content": "メールアドレスの確認が完了しました。 引き続き CircleCI をお楽しみください。",
    "vcsAuthorized.hero.header": "素晴らしいものをビルドしましょう",
    "vcsAuthorized.hero.signUpTitle": "アカウントをお持ちでない場合",
    "vcsAuthorized.hero.signUpBtn": "登録",
    "vcsAuthorized.hero.logInBtn": "ログイン",
    "vcsAuthorized.hero.titleBtns": "CircleCI にログイン",
    "vcsAuthorized.hero.welcomeTitleBtn": "CircleCI にお帰りなさい！",
    "vcsAuthorized.hero.primaryBtn": "GitHubでログイン",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "2023年9月以前に GitHub またはBitbucket",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "で CircleCI にサインアップしましたか？",
    "vcsAuthorized.hero.primaryBtnDropdown1": "すべてのリポジトリ",
    "vcsAuthorized.hero.primaryBtnDropdown2": "パブリックリポジトリのみ",
    "vcsAuthorized.hero.secondaryBtn": "Bitbucket Cloud でログイン",
    "vcsAuthorized.hero.emailBtn": "メールアドレスでログイン",
    "vcsAuthorized-enterprise.hero.header": "Log in to CircleCI server",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCI's continuous integration and deployment platform helps software teams rapidly ship code they trust by automating the build, test, and deploy process.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Log In With GitHub",
    "twitter.screenName": "CircleCIJapan",
    "twitter.paragraph": "CircleCI は現在英語版のみ用意しています。今後の日本語化の予定や日本語でのサポートについては、CircleCI の公式 Twitter アカウントをチェックしてください。",
    "twitter.firstLine": "CircleCI は現在英語版のみ用意しています。",
    "disclaimer.part1.signup": "ログインまたはユーザー登録することで、当社の",
    "disclaimer.part1.vcsAuthorized": "ログインまたはユーザー登録することで、当社の",
    "disclaimer.link1Text": "SaaS利用規約",
    "disclaimer.part2": "および",
    "disclaimer.link2Text": "プライバシーポリシー",
    "recaptcha.part1": "情報は reCAPTCHA によって保護されており、Google の",
    "recaptcha.privacyLinkText": "プライバシーポリシー",
    "recaptcha.part2": "および",
    "recaptcha.termsLinkText": "利用規約",
    "recaptcha.part3": "が適用されます。",
    "ff_recaptcha.part1": "情報は reCAPTCHA によって保護されており、Google の",
    "ff_recaptcha.termsLinkText": "利用規約",
    "ff_recaptcha.part2": "が適用されます。",
    "siteMap.column1.heading": "プロダクト",
    "siteMap.column2.heading": "サポート",
    "siteMap.column3.heading": "リソース",
    "siteMap.column4.heading": "企業情報",
    "siteMap.column5.heading": "はじめに",
    "siteMap.linkOverview.text": "製品概要",
    "siteMap.linkPricing.text": "料金プラン",
    "siteMap.linkIntegrations.text": "連携ツール",
    "siteMap.linkOpenSource.text": "オープンソース",
    "siteMap.linkEnterprise.text": "エンタープライズ",
    "siteMap.linkChangeLog.text": "更新履歴",
    "siteMap.linkDocumentation.text": "ドキュメント",
    "siteMap.linkSupport.text": "ヘルプ",
    "siteMap.linkForum.text": "公式コミュニティ",
    "siteMap.linkStatus.text": "サービス稼働状況",
    "siteMap.linkRequests.text": "改善要望",
    "siteMap.linkPremium.text": "有償サポート",
    "siteMap.linkBlog.text": "ブログ",
    "siteMap.linkCaseStudies.text": "事例紹介",
    "siteMap.linkEbooks.text": "eBook",
    "siteMap.linkReports.text": "レポート",
    "siteMap.linkWebinars.text": "オンライン講座",
    "siteMap.linkVideos.text": "動画解説",
    "siteMap.linkAbout.text": "会社概要",
    "siteMap.linkCareers.text": "採用 ",
    "siteMap.linkCareers.subText": "(募集中)",
    "siteMap.linkTeam.text": "チームスタッフ",
    "siteMap.linkContact.text": "お問い合わせ",
    "siteMap.linkPartner.text": "パートナー",
    "siteMap.linkPress.text": "メディア掲載",
    "siteMap.linkCI.text": "CI とは？",
    "siteMap.linkGetStarted.text": "CircleCI の導入手順",
    "trustedBy": "世界3万以上の企業が信頼する CI/CDツール",
    "links.internal.signup": "/ja/signup/",
    "links.internal.vcsAuthorize": "/ja/vcs-authorize/",
    "links.external.root": "https://circleci.com/ja/",
    "links.external.product": "https://circleci.com/ja/product/",
    "links.external.pricing": "https://circleci.com/ja/pricing/",
    "links.external.integrations": "https://circleci.com/ja/integrations/",
    "links.external.openSource": "https://circleci.com/ja/open-source/",
    "links.external.enterprise": "https://circleci.com/ja/enterprise/",
    "links.external.changeLog": "https://circleci.com/ja/changelog/",
    "links.external.docs": "https://circleci.com/docs/ja/",
    "links.external.support": "https://support.circleci.com/hc/ja/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/ja/support/plans/",
    "links.external.blog": "https://circleci.com/ja/blog/",
    "links.external.customers": "https://circleci.com/ja/customers/",
    "links.external.ebooks": "https://circleci.com/ja/resources/#ebooks",
    "links.external.reports": "https://circleci.com/ja/resources/#reports",
    "links.external.webinars": "https://circleci.com/ja/resources/webinars/",
    "links.external.videos": "https://circleci.com/ja/resources/#videos",
    "links.external.about": "https://circleci.com/ja/about/",
    "links.external.careers": "https://circleci.com/ja/careers/",
    "links.external.team": "https://circleci.com/ja/about/team/",
    "links.external.contact": "https://circleci.com/ja/contact/",
    "links.external.partners": "https://circleci.com/ja/partners/",
    "links.external.newsroom": "https://circleci.com/ja/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/ja/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/ja/2.0/",
    "links.social.blogFeed": "https://circleci.com/ja/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/CircleCIJapan",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/ja/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/ja/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/ja/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/ja/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/ja/security/",
    "links.external.login": "https://circleci.com/authentication/login?f=gho",
    "links.external.loginBitBucket": "https://circleci.com/authentication/login?f=bbo",
    "links.external.authSignUp": "https://circleci.com/authentication/signup/",
    "links.external.authLogin": "https://circleci.com/authentication/login/",
    "links.external.loginPublic": "https://circleci.com/authentication/login?f=gho&s=public",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=ja",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=ja",
    "vcsAuthorizePage.title": "ログイン - CircleCI",
    "vcsAuthorizePage.metaTitle": "CircleCI にログイン",
    "vcsAuthorizePage.metaDescription": "CircleCI アカウントにログインして、ビルドのステータスを確認しましょう。",
    "vcsAuthorizePage.metaDescriptionEnterprise": "CircleCI アカウントにログインして、ビルドのステータスを確認しましょう。",
    "signupPage.title": "ユーザー登録 - CircleCI",
    "signupPage.metaTitle": "世界中の数百万の開発者に信頼されている CI/CD プラットフォーム",
    "signupPage.metaDescription": "CircleCI に登録すると、Linux、macOS、Windows、Android、GPU、および Docker 上で無料でビルド、テスト、デプロイが可能です。また、GitHub、GitLab、およびBitbucket と連携できます。",
    "signupPage.canonicalLink": "https://circleci.com/ja/signup/"
  },
  "ko": {
    "footer.linkTerms.text": "이용 약관",
    "footer.linkPrivacyPolicy.text": "개인정보 처리방침",
    "footer.linkCookiePolicy.text": "쿠키 정책",
    "footer.linkSecurity.text": "보안",
    "footer.copyright": "© {currentYear} Circle Internet Services, Inc.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "signup.header.linkText1": "이미 계정이 있으신가요?",
    "signup.header.linkText2": " 로그인",
    "signup.hero.header": "지금 빌드하세요",
    "signup.hero.subheader": "첫 번째 성공적인 빌드까지 몇 분 남았습니다.",
    "signup.hero.paragraph": "신용카드가 필요하지 않습니다.",
    "signup.hero.titleBtns": "무료로 빌드 시작하기",
    "signup.hero.primaryBtn": "GitHub로 가입하기",
    "signup.hero.primaryBtnDropdown1": "모든 저장소",
    "signup.hero.primaryBtnDropdown2": "공개 저장소만",
    "signup.hero.secondaryBtn": "Bitbucket Cloud로 가입하기",
    "signup.hero.emailBtn": "이메일로 가입하기",
    "signup.hero.gitlabBtn": "GitLab으로 가입하기",
    "signup.hero.signupBtn": "가입하기",
    "signup.hero.emailLabel": "이메일",
    "signup.hero.emailInstructions": "이메일을 입력하세요. 예: name@domain.com",
    "signup.hero.passwordLabel": "비밀번호",
    "signup.hero.passwordInstructions": "최소 10자 이상이며, 다음 중 3가지를 포함해야 합니다: 숫자, 특수 문자(!@#$%^&*), 대문자 또는 소문자",
    "signup.hero.passwordErrorMsg": "10자 이상 입력하세요. 다음 중 최소 3가지를 포함해야 합니다: 숫자(0-9), 특수 문자(!@#$%^&*), 대문자(A-Z) 또는 소문자(a-z).",
    "signup.hero.dividerText": "또는",
    "signup.hero.ff_vcsSubheading": "GitHub, GitLab 및 Bitbucket과 함께 작동",
    "vcsAuthorize.header.linkText1": "계정이 없으신가요?",
    "vcsAuthorize.header.linkText2": " 가입하기",
    "error.title": "죄송합니다, 문제가 발생했습니다.",
    "error.content": "새로고침 후 다시 시도해 주세요.",
    "signup-stackoverflow.hero.header": "지금 가입하세요",
    "signup-stackoverflow.hero.subheader": "Stack Overflow 청취자 여러분, 환영합니다!",
    "signup-stackoverflow.hero.paragraph": "무료 플랜으로 시작하고 완전 호스팅된 클라우드 플랫폼에서 무제한 프로젝트에 접근하세요.",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "빌드 시작하기",
    "signup-stackoverflow.hero.primaryBtn": "GitHub로 가입하기",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "모든 저장소",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "공개 저장소만",
    "signup-stackoverflow.hero.secondaryBtn": "Bitbucket Cloud로 가입하기",
    "signup-stackoverflow.hero.emailBtn": "이메일로 가입하기",
    "signup-stackoverflow.hero.gitlabBtn": "GitLab으로 가입하기",
    "vcsAuthorized.hero.alert.sessionExpired.title": "세션이 만료되었습니다.",
    "vcsAuthorized.hero.alert.sessionExpired.content": "다시 로그인해 주세요.",
    "vcsAuthorized.hero.alert.emailVerified.title": "이메일이 확인되었습니다.",
    "vcsAuthorized.hero.alert.emailVerified.content": "이메일이 확인되었습니다. 이제 애플리케이션을 계속 사용할 수 있습니다.",
    "vcsAuthorized.hero.header": "무언가 멋진 것을 만드세요.",
    "vcsAuthorized.hero.paragraph": "인증을 위해 GitHub 또는 Bitbucket으로 이동합니다.",
    "vcsAuthorized.hero.signUpTitle": "계정이 없으신가요?",
    "vcsAuthorized.hero.signUpBtn": "가입하기",
    "vcsAuthorized.hero.logInBtn": "로그인",
    "vcsAuthorized.hero.titleBtns": "CircleCI에 로그인",
    "vcsAuthorized.hero.welcomeTitleBtn": "CircleCI에 다시 오신 것을 환영합니다!",
    "vcsAuthorized.hero.primaryBtn": "GitHub로 로그인",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "2023년 9월 이전에 GitHub 또는 ",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "Bitbucket으로 CircleCI에 가입하셨나요?",
    "vcsAuthorized.hero.primaryBtnDropdown1": "모든 저장소",
    "vcsAuthorized.hero.primaryBtnDropdown2": "공개 저장소만",
    "vcsAuthorized.hero.secondaryBtn": "Bitbucket Cloud로 로그인",
    "vcsAuthorized.hero.emailBtn": "이메일로 로그인",
    "vcsAuthorized-enterprise.hero.header": "CircleCI 서버에 로그인",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCI의 지속적 통합 및 배포 플랫폼은 빌드, 테스트, 배포 프로세스를 자동화하여 소프트웨어 팀이 신뢰할 수 있는 코드를 빠르게 제공할 수 있도록 돕습니다.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "GitHub로 로그인",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "로그인하거나 가입함으로써 귀하는 다음에 동의하게 됩니다:",
    "disclaimer.part1.vcsAuthorized": "로그인하거나 가입함으로써 귀하는 다음에 동의하게 됩니다:",
    "disclaimer.link1Text": "SaaS 계약",
    "disclaimer.part2": "및",
    "disclaimer.link2Text": "개인정보 처리방침",
    "recaptcha.part1": " reCAPTCHA로 보호됨, Google ",
    "recaptcha.privacyLinkText": "개인정보 처리방침",
    "recaptcha.part2": " 및 ",
    "recaptcha.termsLinkText": "서비스 약관",
    "recaptcha.part3": "이 적용됩니다.",
    "siteMap.column1.heading": "제품",
    "siteMap.column2.heading": "지원",
    "siteMap.column3.heading": "리소스",
    "siteMap.column4.heading": "회사",
    "siteMap.column5.heading": "시작하기",
    "siteMap.linkOverview.text": "개요",
    "siteMap.linkPricing.text": "가격",
    "siteMap.linkIntegrations.text": "통합",
    "siteMap.linkOpenSource.text": "오픈 소스",
    "siteMap.linkEnterprise.text": "엔터프라이즈",
    "siteMap.linkChangeLog.text": "변경 로그",
    "siteMap.linkDocumentation.text": "문서",
    "siteMap.linkSupport.text": "지원 받기",
    "siteMap.linkForum.text": "커뮤니티 포럼",
    "siteMap.linkStatus.text": "시스템 상태",
    "siteMap.linkRequests.text": "기능 요청",
    "siteMap.linkPremium.text": "프리미엄 지원",
    "siteMap.linkBlog.text": "블로그",
    "siteMap.linkCaseStudies.text": "사례 연구",
    "siteMap.linkEbooks.text": "전자책",
    "siteMap.linkReports.text": "보고서",
    "siteMap.linkWebinars.text": "웨비나",
    "siteMap.linkVideos.text": "동영상",
    "siteMap.linkAbout.text": "회사 소개",
    "siteMap.linkCareers.text": "채용 정보 ",
    "siteMap.linkCareers.subText": "(채용 중!)",
    "siteMap.linkTeam.text": "팀",
    "siteMap.linkContact.text": "문의하기",
    "siteMap.linkPartner.text": "파트너 되기",
    "siteMap.linkPress.text": "뉴스룸",
    "siteMap.linkCI.text": "CI란 무엇인가?",
    "siteMap.linkGetStarted.text": "CircleCI에서 시작하는 방법",
    "trustedBy": "30,000개 이상의 조직이 신뢰하는",
    "links.internal.signup": "/ko/signup/",
    "links.internal.vcsAuthorize": "/ko/vcs-authorize/",
    "links.external.root": "https://circleci.com/ko/",
    "links.external.product": "https://circleci.com/product/",
    "links.external.pricing": "https://circleci.com/pricing/",
    "links.external.integrations": "https://circleci.com/integrations/",
    "links.external.openSource": "https://circleci.com/open-source/",
    "links.external.enterprise": "https://circleci.com/enterprise/",
    "links.external.changeLog": "https://circleci.com/changelog/",
    "links.external.docs": "https://circleci.com/docs/",
    "links.external.support": "https://support.circleci.com/hc/en-us/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/customers/",
    "links.external.ebooks": "https://circleci.com/resources/#ebooks",
    "links.external.reports": "https://circleci.com/resources/#reports",
    "links.external.webinars": "https://circleci.com/resources/webinars/",
    "links.external.videos": "https://circleci.com/resources/#videos",
    "links.external.about": "https://circleci.com/about/",
    "links.external.careers": "https://circleci.com/careers/",
    "links.external.team": "https://circleci.com/about/team/",
    "links.external.contact": "https://circleci.com/ko/contact/",
    "links.external.partners": "https://circleci.com/partners/",
    "links.external.newsroom": "https://circleci.com/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/ko/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/circleci",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/security/",
    "links.external.login": "https://circleci.com/authentication/login?f=gho",
    "links.external.loginBitBucket": "https://circleci.com/authentication/login?f=bbo",
    "links.external.authSignUp": "https://circleci.com/authentication/signup/",
    "links.external.authLogin": "https://circleci.com/authentication/login/",
    "links.external.loginPublic": "https://circleci.com/authentication/login?f=gho&s=public",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=ko-KR",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=ko-KR",
    "vcsAuthorizePage.title": "로그인 - CircleCI",
    "vcsAuthorizePage.metaTitle": "CircleCI에 로그인",
    "vcsAuthorizePage.metaDescription": "CircleCI 계정에 로그인하여 빌드 상태를 확인하세요. 계정이 없으신가요? https://circleci.com/ko/signup/ 에서 가입하세요.",
    "vcsAuthorizePage.metaDescriptionEnterprise": "CircleCI 계정에 로그인",
    "signupPage.title": "가입 - CircleCI",
    "signupPage.metaTitle": "무료로 CI/CD 파이프라인 구축 시작하기",
    "signupPage.metaDescription": "CircleCI에 가입하고 매월 6,000분의 무료 빌드 시간을 영구적으로 이용하세요. GitHub, GitLab 또는 Bitbucket의 VCS에 CircleCI를 연결하고 Linux, macOS, Windows, Android, GPU 및 Docker에서 빌드하세요.",
    "signupPage.canonicalLink": "https://circleci.com/ko/signup/"
  },
  "ru": {
    "footer.linkTerms.text": "Условия использования",
    "footer.linkPrivacyPolicy.text": "Политика конфиденциальности",
    "footer.linkCookiePolicy.text": "Политика использования файлов cookie",
    "footer.linkSecurity.text": "Безопасность",
    "footer.copyright": "© {currentYear} Circle Internet Services, Inc.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., Все права защищены.",
    "signup.header.linkText1": "Уже есть аккаунт?",
    "signup.header.linkText2": " Войти",
    "signup.hero.header": "начните разработку",
    "signup.hero.subheader": "Всего несколько минут до вашей первой успешной сборки.",
    "signup.hero.paragraph": "Банковская карта не требуется.",
    "signup.hero.titleBtns": "Начните бесплатно",
    "signup.hero.primaryBtn": "Регистрация через GitHub",
    "signup.hero.primaryBtnDropdown1": "Все репозитории",
    "signup.hero.primaryBtnDropdown2": "Только публичные репозитории",
    "signup.hero.secondaryBtn": "Регистрация через Bitbucket Cloud",
    "signup.hero.emailBtn": "Регистрация по email",
    "signup.hero.gitlabBtn": "Регистрация через GitLab",
    "signup.hero.signupBtn": "Зарегистрироваться",
    "signup.hero.emailLabel": "Email",
    "signup.hero.emailInstructions": "Введите ваш email. Пример: name@domain.com",
    "signup.hero.passwordLabel": "Пароль",
    "signup.hero.passwordInstructions": "Используйте не менее 10 символов, включая 3 из следующих типов: цифры, специальные символы (!@#$%^&*), прописные или строчные буквы",
    "signup.hero.passwordErrorMsg": "Введите 10 или более символов. Включите как минимум 3 из следующих: цифры (0-9), специальные символы (!@#$%^&*), прописные буквы (A-Z) или строчные буквы (a-z).",
    "signup.hero.dividerText": "или",
    "signup.hero.ff_vcsSubheading": "Работает с GitHub, GitLab и Bitbucket",
    "vcsAuthorize.header.linkText1": "Нет аккаунта?",
    "vcsAuthorize.header.linkText2": " Зарегистрироваться",
    "error.title": "Извините, что-то пошло не так.",
    "error.content": "Пожалуйста, обновите страницу и попробуйте снова.",
    "signup-stackoverflow.hero.header": "Зарегистрируйтесь сейчас",
    "signup-stackoverflow.hero.subheader": "Добро пожаловать, слушатели Stack Overflow!",
    "signup-stackoverflow.hero.paragraph": "Начните работу с бесплатным планом и получите доступ к неограниченному количеству проектов на нашей полностью управляемой облачной платформе.",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "Начать разработку",
    "signup-stackoverflow.hero.primaryBtn": "Регистрация через GitHub",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "Все репозитории",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "Только публичные репозитории",
    "signup-stackoverflow.hero.secondaryBtn": "Регистрация через Bitbucket Cloud",
    "signup-stackoverflow.hero.emailBtn": "Регистрация по email",
    "signup-stackoverflow.hero.gitlabBtn": "Регистрация через GitLab",
    "vcsAuthorized.hero.alert.sessionExpired.title": "Ваша сессия истекла.",
    "vcsAuthorized.hero.alert.sessionExpired.content": "Пожалуйста, войдите снова.",
    "vcsAuthorized.hero.alert.emailVerified.title": "Email подтвержден.",
    "vcsAuthorized.hero.alert.emailVerified.content": "Ваш email подтвержден. Вы можете продолжить работу с приложением.",
    "vcsAuthorized.hero.header": "создавайте что-то великое.",
    "vcsAuthorized.hero.paragraph": "Вы будете перенаправлены на GitHub или Bitbucket для аутентификации.",
    "vcsAuthorized.hero.signUpTitle": "Нет аккаунта?",
    "vcsAuthorized.hero.signUpBtn": "Зарегистрироваться",
    "vcsAuthorized.hero.logInBtn": "Войти",
    "vcsAuthorized.hero.titleBtns": "Войти в CircleCI",
    "vcsAuthorized.hero.welcomeTitleBtn": "С возвращением в CircleCI!",
    "vcsAuthorized.hero.primaryBtn": "Войти через GitHub",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "Регистрировались в CircleCI через GitHub или ",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "Bitbucket до сентября 2023?",
    "vcsAuthorized.hero.primaryBtnDropdown1": "Все репозитории",
    "vcsAuthorized.hero.primaryBtnDropdown2": "Только публичные репозитории",
    "vcsAuthorized.hero.secondaryBtn": "Войти через Bitbucket Cloud",
    "vcsAuthorized.hero.emailBtn": "Войти по email",
    "vcsAuthorized-enterprise.hero.header": "Войти в CircleCI server",
    "vcsAuthorized-enterprise.hero.paragraph": "Платформа непрерывной интеграции и развертывания CircleCI помогает командам разработчиков быстро выпускать надежный код, автоматизируя процессы сборки, тестирования и развертывания.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Войти через GitHub",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "Выполняя вход или регистрацию, вы соглашаетесь с нашим",
    "disclaimer.part1.vcsAuthorized": "Выполняя вход или регистрацию, вы соглашаетесь с нашим",
    "disclaimer.link1Text": "Соглашением об использовании SaaS",
    "disclaimer.part2": "и",
    "disclaimer.link2Text": "Политикой конфиденциальности",
    "recaptcha.part1": " Защищено reCAPTCHA, применяются ",
    "recaptcha.privacyLinkText": "Политика конфиденциальности",
    "recaptcha.part2": " и ",
    "recaptcha.termsLinkText": "Условия использования",
    "recaptcha.part3": " Google.",
    "siteMap.column1.heading": "Продукт",
    "siteMap.column2.heading": "Поддержка",
    "siteMap.column3.heading": "Ресурсы",
    "siteMap.column4.heading": "Компания",
    "siteMap.column5.heading": "Начало работы",
    "siteMap.linkOverview.text": "Обзор",
    "siteMap.linkPricing.text": "Цены",
    "siteMap.linkIntegrations.text": "Интеграции",
    "siteMap.linkOpenSource.text": "Open Source",
    "siteMap.linkEnterprise.text": "Enterprise",
    "siteMap.linkChangeLog.text": "История изменений",
    "siteMap.linkDocumentation.text": "Документация",
    "siteMap.linkSupport.text": "Получить поддержку",
    "siteMap.linkForum.text": "Форум сообщества",
    "siteMap.linkStatus.text": "Статус системы",
    "siteMap.linkRequests.text": "Запросы функций",
    "siteMap.linkPremium.text": "Премиум поддержка",
    "siteMap.linkBlog.text": "Блог",
    "siteMap.linkCaseStudies.text": "Примеры внедрения",
    "siteMap.linkEbooks.text": "Электронные книги",
    "siteMap.linkReports.text": "Отчеты",
    "siteMap.linkWebinars.text": "Вебинары",
    "siteMap.linkVideos.text": "Видео",
    "siteMap.linkAbout.text": "О нас",
    "siteMap.linkCareers.text": "Вакансии ",
    "siteMap.linkCareers.subText": "(Мы нанимаем!)",
    "siteMap.linkTeam.text": "Команда",
    "siteMap.linkContact.text": "Связаться с нами",
    "siteMap.linkPartner.text": "Стать партнером",
    "siteMap.linkPress.text": "Пресс-центр",
    "siteMap.linkCI.text": "Что такое CI?",
    "siteMap.linkGetStarted.text": "Как начать работу с CircleCI",
    "trustedBy": "Нам доверяют более 30 000 организаций",
    "links.internal.signup": "/ru/signup/",
    "links.internal.vcsAuthorize": "/ru/vcs-authorize/",
    "links.external.root": "https://circleci.com/ru/",
    "links.external.product": "https://circleci.com/product/",
    "links.external.pricing": "https://circleci.com/pricing/",
    "links.external.integrations": "https://circleci.com/integrations/",
    "links.external.openSource": "https://circleci.com/open-source/",
    "links.external.enterprise": "https://circleci.com/enterprise/",
    "links.external.changeLog": "https://circleci.com/changelog/",
    "links.external.docs": "https://circleci.com/docs/",
    "links.external.support": "https://support.circleci.com/hc/en-us/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/customers/",
    "links.external.ebooks": "https://circleci.com/resources/#ebooks",
    "links.external.reports": "https://circleci.com/resources/#reports",
    "links.external.webinars": "https://circleci.com/resources/webinars/",
    "links.external.videos": "https://circleci.com/resources/#videos",
    "links.external.about": "https://circleci.com/about/",
    "links.external.careers": "https://circleci.com/careers/",
    "links.external.team": "https://circleci.com/about/team/",
    "links.external.contact": "https://circleci.com/ru/contact/",
    "links.external.partners": "https://circleci.com/partners/",
    "links.external.newsroom": "https://circleci.com/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/ru/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/circleci",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/security/",
    "links.external.login": "https://circleci.com/authentication/login?f=gho",
    "links.external.loginBitBucket": "https://circleci.com/authentication/login?f=bbo",
    "links.external.authSignUp": "https://circleci.com/authentication/signup/",
    "links.external.authLogin": "https://circleci.com/authentication/login/",
    "links.external.loginPublic": "https://circleci.com/authentication/login?f=gho&s=public",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=ru-RU",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=ru-RU",
    "vcsAuthorizePage.title": "Вход - CircleCI",
    "vcsAuthorizePage.metaTitle": "Войдите в CircleCI",
    "vcsAuthorizePage.metaDescription": "Войдите в свой аккаунт CircleCI, чтобы просмотреть статус ваших сборок. Если у вас нет аккаунта, зарегистрируйтесь на https://circleci.com/ru/signup/",
    "vcsAuthorizePage.metaDescriptionEnterprise": "Войдите в свой аккаунт CircleCI",
    "signupPage.title": "Регистрация - CircleCI",
    "signupPage.metaTitle": "Начните создавать CI/CD пайплайны бесплатно уже сегодня",
    "signupPage.metaDescription": "Зарегистрируйтесь в CircleCI и получите 6000 бесплатных минут сборки в месяц навсегда. Подключите CircleCI к вашей системе контроля версий на GitHub, GitLab или Bitbucket и выполняйте сборки на Linux, macOS, Windows, Android, GPU и Docker.",
    "signupPage.canonicalLink": "https://circleci.com/ru/signup/"
  },
  "zh": {
    "footer.linkTerms.text": "使用条款",
    "footer.linkPrivacyPolicy.text": "隐私政策",
    "footer.linkCookiePolicy.text": "Cookie 政策",
    "footer.linkSecurity.text": "安全",
    "footer.copyright": "© {currentYear} Circle Internet Services, Inc.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., 保留所有权利。",
    "signup.header.linkText1": "已有账号？",
    "signup.header.linkText2": " 登录",
    "signup.hero.header": "即刻开始构建",
    "signup.hero.subheader": "几分钟内即可完成首次绿色构建。",
    "signup.hero.paragraph": "无需信用卡。",
    "signup.hero.titleBtns": "免费开始构建",
    "signup.hero.primaryBtn": "使用 GitHub 注册",
    "signup.hero.primaryBtnDropdown1": "所有仓库",
    "signup.hero.primaryBtnDropdown2": "仅公开仓库",
    "signup.hero.secondaryBtn": "使用 Bitbucket Cloud 注册",
    "signup.hero.emailBtn": "使用邮箱注册",
    "signup.hero.gitlabBtn": "使用 GitLab 注册",
    "signup.hero.signupBtn": "注册",
    "signup.hero.emailLabel": "邮箱",
    "signup.hero.emailInstructions": "输入您的邮箱。示例：name@domain.com",
    "signup.hero.passwordLabel": "密码",
    "signup.hero.passwordInstructions": "密码长度至少为10个字符，且需包含以下3种类型：数字、特殊字符（!@#$%^&*）、大写或小写字母",
    "signup.hero.passwordErrorMsg": "请输入10个或更多字符。至少包含以下3种类型：数字（0-9）、特殊字符（!@#$%^&*）、大写字母（A-Z）或小写字母（a-z）。",
    "signup.hero.dividerText": "或",
    "signup.hero.ff_vcsSubheading": "支持 GitHub、GitLab 和 Bitbucket",
    "vcsAuthorize.header.linkText1": "没有账号？",
    "vcsAuthorize.header.linkText2": " 注册",
    "error.title": "抱歉，出现了错误。",
    "error.content": "请刷新页面重试。",
    "signup-stackoverflow.hero.header": "立即注册",
    "signup-stackoverflow.hero.subheader": "欢迎，Stack Overflow 用户！",
    "signup-stackoverflow.hero.paragraph": "从我们的免费计划开始，在我们完全托管的云平台上获取无限项目访问权限。",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "开始构建",
    "signup-stackoverflow.hero.primaryBtn": "使用 GitHub 注册",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "所有仓库",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "仅公开仓库",
    "signup-stackoverflow.hero.secondaryBtn": "使用 Bitbucket Cloud 注册",
    "signup-stackoverflow.hero.emailBtn": "使用邮箱注册",
    "signup-stackoverflow.hero.gitlabBtn": "使用 GitLab 注册",
    "vcsAuthorized.hero.alert.sessionExpired.title": "您的会话已过期。",
    "vcsAuthorized.hero.alert.sessionExpired.content": "请重新登录。",
    "vcsAuthorized.hero.alert.emailVerified.title": "邮箱已验证。",
    "vcsAuthorized.hero.alert.emailVerified.content": "您的邮箱已验证。现在可以继续使用应用程序。",
    "vcsAuthorized.hero.header": "构建精彩项目。",
    "vcsAuthorized.hero.paragraph": "您将被引导至 GitHub 或 Bitbucket 进行身份验证。",
    "vcsAuthorized.hero.signUpTitle": "没有账号？",
    "vcsAuthorized.hero.signUpBtn": "注册",
    "vcsAuthorized.hero.logInBtn": "登录",
    "vcsAuthorized.hero.titleBtns": "登录 CircleCI",
    "vcsAuthorized.hero.welcomeTitleBtn": "欢迎回到 CircleCI！",
    "vcsAuthorized.hero.primaryBtn": "使用 GitHub 登录",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "在2023年9月之前使用 GitHub 或",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "Bitbucket 注册过 CircleCI？",
    "vcsAuthorized.hero.primaryBtnDropdown1": "所有仓库",
    "vcsAuthorized.hero.primaryBtnDropdown2": "仅公开仓库",
    "vcsAuthorized.hero.secondaryBtn": "使用 Bitbucket Cloud 登录",
    "vcsAuthorized.hero.emailBtn": "使用邮箱登录",
    "vcsAuthorized-enterprise.hero.header": "登录 CircleCI 服务器",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCI 的持续集成和部署平台通过自动化构建、测试和部署流程，帮助软件团队快速交付可信赖的代码。",
    "vcsAuthorized-enterprise.hero.primaryBtn": "使用 GitHub 登录",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "登录或注册即表示您同意我们的",
    "disclaimer.part1.vcsAuthorized": "登录或注册即表示您同意我们的",
    "disclaimer.link1Text": "SaaS 协议",
    "disclaimer.part2": "和",
    "disclaimer.link2Text": "隐私政策",
    "recaptcha.part1": " 由 reCAPTCHA 保护，适用 Google ",
    "recaptcha.privacyLinkText": "隐私政策",
    "recaptcha.part2": " 和 ",
    "recaptcha.termsLinkText": "服务条款",
    "recaptcha.part3": "。",
    "siteMap.column1.heading": "产品",
    "siteMap.column2.heading": "支持",
    "siteMap.column3.heading": "资源",
    "siteMap.column4.heading": "公司",
    "siteMap.column5.heading": "入门",
    "siteMap.linkOverview.text": "概览",
    "siteMap.linkPricing.text": "定价",
    "siteMap.linkIntegrations.text": "集成",
    "siteMap.linkOpenSource.text": "开源",
    "siteMap.linkEnterprise.text": "企业版",
    "siteMap.linkChangeLog.text": "更新日志",
    "siteMap.linkDocumentation.text": "文档",
    "siteMap.linkSupport.text": "获取支持",
    "siteMap.linkForum.text": "社区论坛",
    "siteMap.linkStatus.text": "系统状态",
    "siteMap.linkRequests.text": "功能请求",
    "siteMap.linkPremium.text": "高级支持",
    "siteMap.linkBlog.text": "博客",
    "siteMap.linkCaseStudies.text": "案例研究",
    "siteMap.linkEbooks.text": "电子书",
    "siteMap.linkReports.text": "报告",
    "siteMap.linkWebinars.text": "网络研讨会",
    "siteMap.linkVideos.text": "视频",
    "siteMap.linkAbout.text": "关于我们",
    "siteMap.linkCareers.text": "职业机会 ",
    "siteMap.linkCareers.subText": "（我们在招聘！）",
    "siteMap.linkTeam.text": "团队",
    "siteMap.linkContact.text": "联系我们",
    "siteMap.linkPartner.text": "成为合作伙伴",
    "siteMap.linkPress.text": "新闻中心",
    "siteMap.linkCI.text": "什么是 CI？",
    "siteMap.linkGetStarted.text": "如何开始使用 CircleCI",
    "trustedBy": "超过30,000家组织的信赖之选",
    "links.internal.signup": "/zh/signup/",
    "links.internal.vcsAuthorize": "/zh/vcs-authorize/",
    "links.external.root": "https://circleci.com/zh/",
    "links.external.product": "https://circleci.com/product/",
    "links.external.pricing": "https://circleci.com/pricing/",
    "links.external.integrations": "https://circleci.com/integrations/",
    "links.external.openSource": "https://circleci.com/open-source/",
    "links.external.enterprise": "https://circleci.com/enterprise/",
    "links.external.changeLog": "https://circleci.com/changelog/",
    "links.external.docs": "https://circleci.com/docs/",
    "links.external.support": "https://support.circleci.com/hc/en-us/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/customers/",
    "links.external.ebooks": "https://circleci.com/resources/#ebooks",
    "links.external.reports": "https://circleci.com/resources/#reports",
    "links.external.webinars": "https://circleci.com/resources/webinars/",
    "links.external.videos": "https://circleci.com/resources/#videos",
    "links.external.about": "https://circleci.com/about/",
    "links.external.careers": "https://circleci.com/careers/",
    "links.external.team": "https://circleci.com/about/team/",
    "links.external.contact": "https://circleci.com/ru/contact/",
    "links.external.partners": "https://circleci.com/partners/",
    "links.external.newsroom": "https://circleci.com/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/zh/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/circleci",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/security/",
    "links.external.login": "https://circleci.com/authentication/login?f=gho",
    "links.external.loginBitBucket": "https://circleci.com/authentication/login?f=bbo",
    "links.external.authSignUp": "https://circleci.com/authentication/signup/",
    "links.external.authLogin": "https://circleci.com/authentication/login/",
    "links.external.loginPublic": "https://circleci.com/authentication/login?f=gho&s=public",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=zh-CN",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=zh-CN",
    "vcsAuthorizePage.title": "登录 - CircleCI",
    "vcsAuthorizePage.metaTitle": "登录访问 CircleCI",
    "vcsAuthorizePage.metaDescription": "登录您的 CircleCI 账号查看构建状态。如果您还没有账号，请访问 https://circleci.com/zh/signup/ 注册",
    "vcsAuthorizePage.metaDescriptionEnterprise": "登录您的 CircleCI 账号",
    "signupPage.title": "注册 - CircleCI",
    "signupPage.metaTitle": "立即免费开始构建 CI/CD 流水线",
    "signupPage.metaDescription": "注册 CircleCI 即可永久获得每月6,000分钟的免费构建时间。将 CircleCI 连接到您的 GitHub、GitLab 或 Bitbucket 版本控制系统，在 Linux、macOS、Windows, Android, GPU, and Docker.",
    "signupPage.canonicalLink": "https://circleci.com/zh/signup/"
  }
};
export default i18nTranslations;
